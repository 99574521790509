import { LucideCheckCircle2 } from "lucide-react";
import React from "react";

const Services = () => {
  return (
    <div className="w-full min-w-[100vw] h-fit mt-20">
      <h1 className="text-3xl font-semibold text-zinc-800 px-20 py-10">
        Our Services
      </h1>

      <div
        className=" rounded-lg mx-20 py-5 mt-10
       bg-gradient-to-br from-sky-100 via-zinc-200 to-sky-100"
      >
        <div className="flex justify-center gap-20 mt-10 w-fit mx-auto px-8 py-5 rounded-xl text-slate-900 bg-stone-700/30 ">
          <ServiceEntry label="Search" isSelected={true} />
          <ServiceEntry label="Social" isSelected={false} />
          <ServiceEntry label="Content" isSelected={false} />
          <ServiceEntry label="Paid Ads" isSelected={false} />
        </div>
        <ServiceDescription />
      </div>
    </div>
  );
};

const ServiceEntry = (props: { label: string; isSelected: boolean }) => {
  const { label, isSelected } = props;
  return (
    <div className="relative">
      <div className="flex gap-1.5  rounded-lg items-center ">
        {/* <LucideCheckCircle2 strokeWidth={1.2} size={18} color="green" /> */}
        {isSelected && (
          <>
            <div className="w-2 h-2 rounded-full bg-yellow-900"></div>
            <p className="font-medium text-xl">{label}</p>
          </>
        )}
        {!isSelected && (
          <>
            <div className="w-[5px] h-[5px] rounded-full bg-yellow-900"></div>
            <p className="font-medium text-xl">{label}</p>
          </>
        )}
      </div>
    </div>
  );
};

const ServiceDescription = () => {
  return (
    <div className="flex flex-col gap-5  rounded-lg  px-10 py-8 font-medium text-base text-slate-800">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis
        dolore quibusdam nesciunt sapiente dignissimos qui facilis expedita
        ipsum maiores incidunt mollitia perspiciatis repudiandae ipsa, magnam
        fugiat tempora tenetur. Non dolorem quam soluta impedit tempora
        doloremque vel eos, ipsum fugit id quibusdam molestias voluptatem
        numquam laudantium, cumque optio eius! Magnam, error.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Inventore eos
        asperiores non numquam ad odio soluta! Illum doloribus ducimus non
        recusandae. Distinctio eaque nemo, blanditiis ab officia aperiam enim ut
        quaerat. Eaque, ipsam modi.
      </p>

      <ul className="list-disc list-inside flex flex-col gap-3">
        <li>Lorem, ipsum.</li>
        <li>Lorem, ipsum dolor.</li>
        <li>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt,
          neque.
        </li>
        <li>Lorem sit amet consectetur adipisicing elit. Incidunt, neque.</li>
        <li>Lorem consectetur adipisicing elit.</li>
        <li>Lorem Incidunt Neque.</li>
      </ul>
    </div>
  );
};

export default Services;
