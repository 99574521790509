import { useEffect, useRef } from "react";

const Hero = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = `${window.innerHeight - ref.current.getBoundingClientRect().top}px`;
    }
  }, []);
  return (
    <div ref={ref} className="flex w-full gap-0 mt-10 h-[660px] ">
      <div className="flex flex-col  w-[60%] px-32  justify-center items-center">
        <h1 className="text-5xl font-semibold text-zinc-700">
          Sell More On Your Store
        </h1>
        <h1 className="text-2xl  text-zinc-700/90 pt-8 pr-20">
          Lorem ipsum
          <span className="font-semibold mx-1 text-zinc-700">dolor,</span>
          sit amet consectetur adipisicing elit.
          <span className="font-semibold mx-1 text-zinc-700">Expedita</span>
          dolores earum quo esse illum dolor laborum
          <span className="font-semibold mx-1 text-zinc-700">
            temporibus cumque.
          </span>
        </h1>

        <div className="flex  w-full  gap-8 mt-32">
          <div className="w-[45%]">
            <CTAButton />
          </div>
          <OtherButton />
        </div>
      </div>

      <div className="relative w-[40%] max-w-[40%] h-full">
        <img
          src="https://s3-alpha-sig.figma.com/img/b4e8/0571/32c7de056f756328b81eb6f387849322?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=K0uEWwmQjRBYTdN~ha9DZ6s6aYI7jL44udMnD9Jzj8LjTPMUmTl93nbQcgH-5sq4pVcCQbcb8tNF-ylsr5JVuo8vBflw73Nt28mhat0CzDiKT-gMfJHzu91PgAtDWGYNWwMM2t4a9YrtEQacd9ii3YqhgX6jwqdgU6kUwiBd4gJ3dJZ0StGTUY7JYpUKqElqNaYrlzzkw30N1kHCzILjM6xNgOMJu78ycZ1ATA1BaltjLk332aaGeODaj0kuVew4Zo45FgZZTfNlKsHdm6mRk8oz1mV2V1Q2dGVz0t4se6b3tMhnqhPu6KtcEpE6vfBb8AhkVdMpHl8Qy1q5HkOWbA__"
          alt=""
          className="w-full h-full object-cover object-[50%_80%]"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-zinc-600/20 via-transparent to-zinc-700/20"></div>
      </div>
    </div>
  );
};

const CTAButton = () => {
  return (
    <div className="relative  h-12 ">
      <div className="bg-amber-700/40 absolute inset-0 z-0 rounded-lg h-full blur-md"></div>
      <button
        className="absolute inset-0 z-10 flex items-center justify-center bg-[rgb(236,84,63)]  rounded-none 
        text-slate-50 tracking-wide text-xs  px-10 py-[17px] font-medium uppercase
      "
      >
        Boost My Sales!
      </button>
    </div>
  );
};

const OtherButton = () => {
  return (
    <button
      className=" flex items-center justify-center text-sm font-barlow px-10 py-[17px]  h-12
        border border-zinc-400/70 text-slate-500"
    >
      How it works
    </button>
  );
};

export default Hero;
