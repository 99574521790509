import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { UserProvider } from "./context/UserProfile.tsx";
import App from "./visible-ads.com-2/App.tsx";
import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";
import { trackPageView } from "./analytics/analytics.ts";

const AppWithTracking = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return <App />;
};

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <Router>
    {/* <UserProvider> */}
    <AppWithTracking />
    <Analytics />
    {/* </UserProvider> */}
  </Router>
  // </React.StrictMode>,
);
