import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import Services from "./Services";

import HowItWorks from "./HowItWorks";
import CaseStudies from "./CaseStudies";
import Awards from "./Awards";

const App = () => {
  return (
    <div className="font-inter font-normal bg-white min-w-[100vw] min-h-screen">
      <Header />
      <Hero />
      <Services />
      <HowItWorks />
      <CaseStudies />
      <Awards />
    </div>
  );
};

export default App;
