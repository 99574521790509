import FunnelImage from "../../images/sales-funnel-story-1-2.jpg";
import Avatars from "../../images/avatars.png";

const CaseStudies = () => {
  return (
    <div className="px-20 mt-20 pb-40">
      <div className="flex flex-col gap-5  text-[#666666] ">
        <h1 className="text-3xl font-semibold text-zinc-800 pt-10 pb-5 ">
          Case Studies
        </h1>
        <p className="text-[#181F3A] pb-10">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa quia
          modi, voluptate cum vel eligendi ea architecto sequi esse nobis!
        </p>
      </div>

      <div className="flex gap-10 justify-center">
        <Card imgSrc="https://s3-alpha-sig.figma.com/img/a62d/16d3/1133ef448851560546a9d9bef808ead2?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=T6BMtnoOVWTYzxv7NgDNW7LeA88~Dt6zUtVGV1-6~tVPQ3oH8-9DMzn16J8Ryu4o-gaMVjyFbYq0fnb9GnZpwTBAVR-a2HeeD6w7k3n-FPC8zVMLPadR9WlfEBnqFYZLBDo9GHsEiAsODpHScI2Kiz1Z9f6yRfo1MD0vdUjKxUZhZdHwWgq8f3p8JcKNpUUGIKmJNyXKi9mBEiWKd-A3nM7d-h7e6g-DGI1KDcqEI4JIM4QJrJv7b0OlJvkqiTyDSRuAHuSu6pOe7HHzYJTHqJm9f1rJvB1VSpX8pxTIeepiaKWWZp99a1HBeNwGJrTW3rMD9gBjly8ZIeLkRT8uFQ__" />

        <Card imgSrc="https://s3-alpha-sig.figma.com/img/edcb/104e/caa24be1028aa716076ed4d1cb1cf609?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=F6TECIxELQ4bbQFZ2-dFHw46HseL2aXI07wcRBf~0ESkkkngYiOCIwLEh8jhCpeyNoezNMnI~o9jVZkouJJfxJBwGQiEBrJP41SHuDtajy3e6ENhyQzCxFaQH4W-J3apr8IiuYhubsDG2PpsHriQ5GtTv2CO45VevXZ4vMDXHJr8SeVxHC5mFfVdGyowQ8UO6TKJeBPyP~PeNUTc9OoxAIM-NUyxT30bh9GSuSPu0eMNUCWfhKSXUoa6sQZGXKO1ucfxbCac2TgzVehPkX9t~Q5oeIevjn7hch9JSVIox7UtJYrg3DNVXpQXfMOoORS7-X4sSzjQg8gnDJ-I1ebrkA__" />

        <Card imgSrc="https://s3-alpha-sig.figma.com/img/a62d/16d3/1133ef448851560546a9d9bef808ead2?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=T6BMtnoOVWTYzxv7NgDNW7LeA88~Dt6zUtVGV1-6~tVPQ3oH8-9DMzn16J8Ryu4o-gaMVjyFbYq0fnb9GnZpwTBAVR-a2HeeD6w7k3n-FPC8zVMLPadR9WlfEBnqFYZLBDo9GHsEiAsODpHScI2Kiz1Z9f6yRfo1MD0vdUjKxUZhZdHwWgq8f3p8JcKNpUUGIKmJNyXKi9mBEiWKd-A3nM7d-h7e6g-DGI1KDcqEI4JIM4QJrJv7b0OlJvkqiTyDSRuAHuSu6pOe7HHzYJTHqJm9f1rJvB1VSpX8pxTIeepiaKWWZp99a1HBeNwGJrTW3rMD9gBjly8ZIeLkRT8uFQ__" />
      </div>

      <div className="flex gap-10 justify-center pt-10">
        <Card imgSrc="https://s3-alpha-sig.figma.com/img/edcb/104e/caa24be1028aa716076ed4d1cb1cf609?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=F6TECIxELQ4bbQFZ2-dFHw46HseL2aXI07wcRBf~0ESkkkngYiOCIwLEh8jhCpeyNoezNMnI~o9jVZkouJJfxJBwGQiEBrJP41SHuDtajy3e6ENhyQzCxFaQH4W-J3apr8IiuYhubsDG2PpsHriQ5GtTv2CO45VevXZ4vMDXHJr8SeVxHC5mFfVdGyowQ8UO6TKJeBPyP~PeNUTc9OoxAIM-NUyxT30bh9GSuSPu0eMNUCWfhKSXUoa6sQZGXKO1ucfxbCac2TgzVehPkX9t~Q5oeIevjn7hch9JSVIox7UtJYrg3DNVXpQXfMOoORS7-X4sSzjQg8gnDJ-I1ebrkA__" />
        <Card imgSrc="https://s3-alpha-sig.figma.com/img/a62d/16d3/1133ef448851560546a9d9bef808ead2?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=T6BMtnoOVWTYzxv7NgDNW7LeA88~Dt6zUtVGV1-6~tVPQ3oH8-9DMzn16J8Ryu4o-gaMVjyFbYq0fnb9GnZpwTBAVR-a2HeeD6w7k3n-FPC8zVMLPadR9WlfEBnqFYZLBDo9GHsEiAsODpHScI2Kiz1Z9f6yRfo1MD0vdUjKxUZhZdHwWgq8f3p8JcKNpUUGIKmJNyXKi9mBEiWKd-A3nM7d-h7e6g-DGI1KDcqEI4JIM4QJrJv7b0OlJvkqiTyDSRuAHuSu6pOe7HHzYJTHqJm9f1rJvB1VSpX8pxTIeepiaKWWZp99a1HBeNwGJrTW3rMD9gBjly8ZIeLkRT8uFQ__" />

        <Card imgSrc="https://s3-alpha-sig.figma.com/img/edcb/104e/caa24be1028aa716076ed4d1cb1cf609?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=F6TECIxELQ4bbQFZ2-dFHw46HseL2aXI07wcRBf~0ESkkkngYiOCIwLEh8jhCpeyNoezNMnI~o9jVZkouJJfxJBwGQiEBrJP41SHuDtajy3e6ENhyQzCxFaQH4W-J3apr8IiuYhubsDG2PpsHriQ5GtTv2CO45VevXZ4vMDXHJr8SeVxHC5mFfVdGyowQ8UO6TKJeBPyP~PeNUTc9OoxAIM-NUyxT30bh9GSuSPu0eMNUCWfhKSXUoa6sQZGXKO1ucfxbCac2TgzVehPkX9t~Q5oeIevjn7hch9JSVIox7UtJYrg3DNVXpQXfMOoORS7-X4sSzjQg8gnDJ-I1ebrkA__" />
      </div>
    </div>
  );
};

const Card = (props: { imgSrc: string }) => {
  return (
    <div className="flex flex-col gap-2">
      <img src={props.imgSrc} alt="" className="w-72 h-48 rounded-lg" />
      <p className="text-[#797979] text-xs">Fri, 27 Sep 2024</p>
      <h1 className="text-[18px] text-slate-800 font-light">
        Lorem ipsum dolor sit amet.
      </h1>
      <p className="text-[#797979] text-sm">
        Lorem ipsum, dolor sit amet consectetur
      </p>
      <p className="text-[#797979] text-sm">
        Adipisicing elit. Excepturi, perspiciatis.
      </p>
    </div>
  );
};

export default CaseStudies;
