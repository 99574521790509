import FunnelImage from "../../images/sales-funnel-story-1-2.jpg";
import Avatars from "../../images/avatars.png";
import { Diamond, DiamondIcon, Trophy } from "lucide-react";

const Awards = () => {
  return (
    <div className="px-20 mt-20 pb-40">
      <div className="flex flex-col gap-5  text-[#666666] ">
        <h1 className="text-3xl font-semibold text-zinc-800 pt-10 pb-5 ">
          Awards
        </h1>
        <p className="text-[#181F3A] pb-10">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa quia
          modi, voluptate cum vel eligendi ea architecto sequi esse nobis!
        </p>
      </div>

      <div className="flex gap-10 justify-center">
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  );
};

const Card = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-center items-center w-60 h-40 rounded-lg bg-zinc-400">
        <Trophy size={80} color="#232323" strokeWidth={0.75} />
      </div>

      <h1 className="text-[18px] text-slate-800 font-light">
        Lorem ipsum dolor sit amet.
      </h1>
    </div>
  );
};

export default Awards;
