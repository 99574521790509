import React from "react";

import Logo from "../../images/visible-ads-logo.png";
const Header = () => {
  return (
    <div>
      <HeaderTopBar />
      <div className="flex items-center justify-between px-8 pt-6 text-stone-800 font-light">
        <div className="w-1/4">
          <img src={Logo} className="w-44 h-10" />
        </div>
        <div className="w-2/4 flex gap-4">
          <div className="flex gap-1 items-center">
            <p>Service</p>
            <ArrowDown />
          </div>
          <div className="flex gap-1 items-center">
            <p>Case Study</p>
            <ArrowDown />
          </div>

          <p>Knowledge</p>
        </div>
        {/* <button className="w-1/4 max-w-60 bg-visible-ads-red text-white py-3 px-8 rounded-md">
          Boost My Sales
        </button> */}
        <div className="relative w-60 h-10">
          <div className="bg-[rgb(236,84,63)]/40 absolute inset-0 z-0 rounded-lg h-full blur-md"></div>
          <button
            className="absolute inset-0 z-10 flex items-center justify-center bg-[rgb(236,84,63)] rounded-none 
        text-slate-50 tracking-wide text-xs  px-10 py-[17px] font-medium uppercase
      "
          >
            Boost My Sales!
          </button>
        </div>
      </div>
    </div>
  );
};

const ArrowDown = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6199 5.22095L7.81655 9.02428C7.36738 9.47345 6.63238 9.47345 6.18322 9.02428L2.37988 5.22095"
        stroke="black"
        stroke-width="1.2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const HeaderTopBar = () => {
  return (
    <div className="flex items-center justify-between px-6 min-w-[100vw] h-fit min-h-12 bg-[#F7F7F7] text-sm text-[#797979] font-normal">
      <p className="">PPC, E-commerce & Sales Funnel Experts Since 2013</p>

      <div className="flex gap-4">
        <div className="flex gap-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M6.9403 20.63C8.4303 21.5 10.1603 22 12.0003 22C17.6303 22 22.3103 17.03 21.9803 11.41C21.6403 5.60997 16.3703 1.13996 10.3003 2.13996C6.12029 2.82996 2.77029 6.21996 2.12029 10.4C1.74029 12.82 2.24031 15.11 3.33031 17L2.4403 20.31C2.2403 21.06 2.93028 21.74 3.67028 21.53L6.9403 20.63Z"
              fill="#FF4431"
            />
            <path
              d="M17 15.17C17 15.35 16.96 15.54 16.87 15.72C16.78 15.9 16.67 16.07 16.53 16.23C16.28 16.5 16.01 16.7 15.71 16.82C15.41 16.95 15.08 17.01 14.73 17.01C14.22 17.01 13.67 16.89 13.1 16.64C12.52 16.39 11.95 16.06 11.38 15.65C10.8 15.23 10.26 14.76 9.73999 14.25C9.21999 13.73 8.76003 13.18 8.34003 12.61C7.93003 12.04 7.59999 11.47 7.35999 10.9C7.11999 10.33 7 9.77998 7 9.25998C7 8.91998 7.05999 8.58998 7.17999 8.28998C7.29999 7.97998 7.49001 7.69997 7.76001 7.44997C8.08001 7.12997 8.42999 6.97998 8.79999 6.97998C8.93999 6.97998 9.08002 7.00998 9.21002 7.06998C9.34002 7.12998 9.45999 7.21998 9.54999 7.34998L10.7 8.99998C10.79 9.12998 10.86 9.23998 10.9 9.34998C10.95 9.45998 10.97 9.55998 10.97 9.65998C10.97 9.77998 10.93 9.89997 10.86 10.02C10.79 10.14 10.7 10.26 10.58 10.38L10.2 10.78C10.14 10.84 10.12 10.9 10.12 10.98C10.12 11.02 10.13 11.06 10.14 11.1C10.16 11.14 10.17 11.17 10.18 11.2C10.27 11.37 10.43 11.58 10.65 11.84C10.88 12.1 11.12 12.37 11.38 12.63C11.65 12.9 11.91 13.14 12.18 13.37C12.44 13.59 12.66 13.74 12.83 13.83C12.86 13.84 12.89 13.86 12.92 13.87C12.96 13.89 13 13.89 13.05 13.89C13.14 13.89 13.2 13.86 13.26 13.8L13.64 13.42C13.77 13.29 13.89 13.2 14 13.14C14.12 13.07 14.23 13.03 14.36 13.03C14.46 13.03 14.56 13.05 14.67 13.1C14.78 13.15 14.9 13.21 15.02 13.3L16.68 14.48C16.81 14.57 16.9 14.68 16.96 14.8C16.97 14.91 17 15.03 17 15.17Z"
              fill="#FF4431"
            />
          </svg>
          <p>+44781047679</p>
        </div>

        <div className="flex gap-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
              fill="#FF4431"
            />
            <path
              d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z"
              fill="#FF4431"
            />
          </svg>
          <p>contact@visible-ads.com</p>
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1.5"
            y="1.5"
            width="21"
            height="21"
            rx="10.5"
            fill="#FF4431"
            fill-opacity="0.2"
          />
          <path
            d="M9.46392 7.26911C9.46392 7.97002 8.85639 8.53822 8.10696 8.53822C7.35753 8.53822 6.75 7.97002 6.75 7.26911C6.75 6.5682 7.35753 6 8.10696 6C8.85639 6 9.46392 6.5682 9.46392 7.26911Z"
            fill="#FF4431"
          />
          <path
            d="M6.93557 9.47107H9.25515V16.5H6.93557V9.47107Z"
            fill="#FF4431"
          />
          <path
            d="M12.9897 9.47107H10.6701V16.5H12.9897C12.9897 16.5 12.9897 14.2872 12.9897 12.9036C12.9897 12.0732 13.2732 11.2392 14.4046 11.2392C15.6833 11.2392 15.6756 12.3259 15.6696 13.1678C15.6618 14.2683 15.6804 15.3914 15.6804 16.5H18V12.7903C17.9804 10.4215 17.3631 9.33006 15.3325 9.33006C14.1265 9.33006 13.379 9.87754 12.9897 10.3729V9.47107Z"
            fill="#FF4431"
          />
        </svg>
      </div>
    </div>
  );
};

export default Header;
