import FunnelImage from "../../images/sales-funnel-story-1-2.jpg";
import Avatars from "../../images/avatars.png";

const HowItWorks = () => {
  return (
    <div className="px-20 mt-20 ">
      <div className="flex flex-col gap-5  text-[#666666] ">
        <h1 className="text-3xl font-semibold text-zinc-800 py-10">
          How it Works
        </h1>
        <p>
          We love our customers and work hard to help them run a profitable
          business.
        </p>
        <p>
          Therefore, we will only run Pay-Per-Click (PPC) ads once we have
          evaluated your sales funnel. We want to make sure that your funnel
          converts sufficiently to achieve your marketing ROI objective.
        </p>
        <p>
          To achieve this, we offer two complimentary
          conversion-rate-optimisation (CRO) consultancy services to our PPC
          clients: the “Perfect Customer Avatar” and “Hook, Story, Offer”
          methodology.
        </p>
      </div>

      <MyGrid />
    </div>
  );
};

const MyGrid = () => {
  return (
    <div
      className="relative flex  w-full  h-fit px-1  py-12 
     "
    >
      <div
        className="flex flex-col items-center  py-20 px-16 gap-20 rounded-xl
       bg-gradient-to-br from-sky-50 via-rose-100 to-sky-50"
      >
        <div className="flex justify-center items-center gap-10">
          <div className="w-[30%]">
            <img
              src={Avatars}
              alt=""
              className="w-full h-full  rounded-lg bg-rose-200 px-5 py-8"
            />
          </div>
          <div className="w-3/5 ">
            <PerfectCustomerAvatar />
          </div>
        </div>
        <div className="flex justify-center items-center gap-20">
          <div className="w-2/5 ">
            <Step2 />
          </div>
          <div className="w-1/2">
            <img
              src={FunnelImage}
              alt=""
              className="w-full h-full  rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const PerfectCustomerAvatar = () => {
  return (
    <>
      <p className="text-[#181F3A] text-2xl">Perfect Customer Avatar</p>
      <p className="text-[#797979] text-sm mt-3">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora,
        aliquid est eum facere eveniet quia similique in nihil, minima, earum
        voluptatibus ratione placeat!
      </p>
      <p className="text-[#797979] text-sm mt-5">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
      </p>
    </>
  );
};

const Step2 = () => {
  return (
    <>
      <p className="text-[#181F3A] text-2xl">Explain Hook, Story, Offer</p>
      <p className="text-[#797979] text-sm mt-3">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora,
        aliquid est eum facere eveniet quia similique in nihil, minima, earum
        voluptatibus ratione placeat!
      </p>
      <p className="text-[#797979] text-sm mt-5">
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
      </p>
      <ul className="text-[#181F3A] mt-5 list-disc list-inside">
        <li>Lorem ipsum dolor sit amet.</li>
        <li className="list-item">Lorem ipsum.</li>
        <li className="list-inside">
          Lorem, ipsum dolor sit amet consectetur adipisicing.
        </li>
      </ul>
    </>
  );
};

export default HowItWorks;
