import ReactGA from 'react-ga';

const TRACKING_ID = `${import.meta.env.VITE_GA_TRACKING_ID}`;

console.log(TRACKING_ID);

ReactGA.initialize(TRACKING_ID);

export const trackPageView = (page:any) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};